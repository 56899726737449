@import "~normalize.css"
@import "~@blueprintjs/core/lib/css/blueprint.css"
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css"
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css"
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css"
@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,800"
@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,800"
@import "@blueprintjs/core/lib/scss/variables"
@import "@blueprintjs/table/lib/css/table.css"

:root
  // z-index
  --page-header-z-index: 5
  --menu-z-index: 10
  --guard-z-index: 15
  --dialog-z-index: 20
  --toast-z-index: 30

body
  margin: 0
  min-height: 100vh
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: 'Lato'
  background: #F5F5F5
  color: #2e4a5c
  font-weight: 400

body:after
  content: ''
  position: fixed
  top: -50%
  right: -50%
  bottom: -50%
  left: -50%
  z-index: -1
  background: #F5F5F5

#root
  width: 100%
  min-height: 100vh
  display: flex
  flex-direction: column
  background: #F5F5F5

*[hidden]
  display: none

.rtl
  direction: rtl

// Common
.fullscreenGuard
  position: absolute
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  z-index: var(--guard-z-index)
  display: flex
  justify-content: center
  // align-items: center
  background-color: #90a2a25d
  backdrop-filter: blur(1.5px)
  overflow: scroll

// global helpers
.margin5
  margin: 5px

//
// Blueprint
//
.bp4-input
  color: #444
  transition: box-shadow 0ms
  box-shadow: inset 0 0 0 1px rgb(17 20 24 / 20%), 0 0.2px 0.5px rgb(17 20 24 / 10%)
  border-radius: 3px
  height: 35px
.bp4-form-group
  // border-radius: 3px
.bp4-input[readonly]
  box-shadow: inset 0 0 0 1px rgba(39, 44, 51, 0.15) !important

.bp4-control-group:not(.bp4-vertical) > :not(:last-child)
  margin-right: unset
  margin-inline-end: 2px

.bp4-input-group .bp4-input-left-container,
.bp4-input-group .bp4-input-actions,
button
  margin-top: 3px
  margin-inline-end: 3px

// checkbox
.bp4-control .bp4-control-indicator
  margin-right: unset
  margin-inline-end: 10px
.bp4-control:not(.bp4-align-right)
  padding-left: unset
  padding-inline-start: 26px
.bp4-control:not(.bp4-align-right) .bp4-control-indicator
  margin-right: unset
  margin-inline-start: -26px
  margin-inline-end: 10px

// dialog
.bp4-dialog.bp4-alert
  margin: 10px

.bp4-dialog.bp4-alert > .bp4-alert-body > .bp4-alert-contents > h3
  margin-top: 9px

.rtl .bp4-alert-body .bp4-icon
  margin-right: 0px
  margin-left: 20px

.rtl .bp4-dialog-header .bp4-icon
  margin-left: 10px
  margin-right: 10px

// menu
.bp4-menu
  text-align: start
.bp4-menu-item
  border-radius: 5px
  outline: none
  margin: 2px 0px
  padding: 6px 12px 6px 12px
  outline: none
  text-align: start
  gap: 7px
.bp4-menu-item:hover,
.bp4-menu-item.bp4-selected,
.bp4-menu-item.bp4-selected:hover
  background-color: #f8c34bd1
  color: #393939

.bp4-menu-item::before, .bp4-menu-item > *
  margin-right: unset

.rtl .bp4-icon.bp4-icon-caret-right
  transform: rotateZ(180deg)

// non-ideal-state
.bp4-non-ideal-state
  height: unset

// tabs
.bp4-tab-list
  overflow-x: scroll
  padding: 4px
.bp4-tab-list::-webkit-scrollbar
  display: none
.bp4-tab
  font-family: "Lato"
  outline: none
.rtl .bp4-tab-list > *:not(:last-child)
  margin-right: 0px
  margin-left: 20px
.rtl .bp4-tab-list .bp4-tab-indicator-wrapper
  margin-left: 0px

// card
.bp4-card.bp4-interactive
  &:hover
    box-shadow: $pt-elevation-shadow-2
  &:active
    opacity: 0.9
    box-shadow: $pt-elevation-shadow-0
    transition-duration: 0

// tag
.bp4-tag
  border-radius: 4px
  padding: 1px 4px 1px 4px
  font-weight: normal
  min-width: 10px

// slider
.bp4-slider-handle > .bp4-slider-label
  background-color: #858687
  font-size: 8pt
.bp4-slider-axis > .bp4-slider-label
  color: #999595

// Table
.rtl .bp5-table-quadrant-left,.bp5-table-quadrant-top-left
  display: none

// Popover
.bp4-popover2
  border-radius: 5px
  .bp4-popover2-content
    border-radius: 5px
    .bp4-input-group
      padding: 10px 8px
      span.bp4-icon
        left: 10px
        top: 10px
      span.bp4-input-action
        right: 8px
        top: 10px
      input
        border-radius: 5px
  .bp4-menu
    border-radius: 5px

.bp4-dialog
  margin: 10px

// ReCharts
.recharts-cartesian-axis-tick
  font-size: 10px

.recharts-legend-item-text
  font-size: 12px
