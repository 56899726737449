/* 
 * Copyright (C) SEARCH7 Ltd (https://search7.com.au) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
.formLabel
  margin-inline-start: 2px
  white-space: nowrap
  display: flex !important

  span[class~="text"]
    flex-grow: 1
    font-style: normal
    font-size: 12px
    display: inline-flex

    span[class~="bp4-popover2-target"]
      line-height: 1px
      height: 12px
      margin-inline-start: 5px !important
      margin-top: 1.5px !important

  button
    min-height: 16px !important
    min-width: 16px !important
    padding: 0px 3px 0px 3px !important
    margin-inline-start: 2px
    margin-inline-end: 0px

    &:not([class*=bp4-intent-])
      color: #e9a610
      span
        color: #e9a610
      svg
        color: #e9a610
        path[class=bp4-spinner-head]
          stroke: #e9a610

    span
      font-size: 12px
      min-width: unset !important
      margin: 0px !important

      svg
        width: 9px
        height: 9px
