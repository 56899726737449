.cartItemCards {
  padding: 10px 15px 5px 15px;

  div[class~="bp4-card"] {
    box-shadow: none;
    border: 1px #e0e0e0 dashed;
    margin-bottom: 10px;
    min-height: 84px;
  }

  .optionGroups {
    padding: 0px;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    min-width: unset;
    width: auto;
    height: unset;
    display: flex;
    flex-direction: column;

    div[class~="bp4-form-group"] {
      margin: 0px;

      label[class~="bp4-form-content"] {
        // color: #2d72d2;
        font-size: 12px !important;
      }
    }
  }

  .productActions {
    margin-top: -9px;
    margin-bottom: 0;
    margin-inline-end: -7px;
    margin-inline-start: 6px;

    button {
      min-height: 25px;
      min-width: 25px;

      span[class~="bp4-icon"] {
        color: #aaaaaa;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

}

.selectProductCard {
  margin: 0px 5px 10px 5px;
  width: auto;
  flex: 1;

  span[class="quantity"] {
    margin-inline-start: 8px;
    border-radius: 5px;
    padding: 1px 4px;
    background-color: #f9bf3b;
    color: #565758;
    font-size: 13px;
    font-weight: bold;
  }
}

span.totalPrice {
  color: #b65959;
  font-weight: bold;
  margin: 0px 4px 0px 0px;
  font-size: 16px;
}

.customerRow {
  align-items: flex-end;
  width: 100%;
}

.customerRow>div {
  flex: 1;
}

.customerRow>span {
  margin: 0 8px 23px 8px;
}

.customItemPriceField div[class~="bp4-button-group"] {
  display: none;
}