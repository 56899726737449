.cartItemCard {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;

  >div[class~="flex-column"] {
    width: 100%;
    min-height: 65px;
    padding: 8px 8px 6px 8px;

    h4 {
      margin: 0px 0px 7px 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      svg {
        margin: -2.5px 5px 0px 0px;
      }
    }

    span[class="quantity"] {
      border-radius: 5px;
      padding: 1px 5px;
      background-color: #f9bf3b;
      color: #565758;
      font-size: 13px;
    }

    .option {
      font-size: 13px;
      margin: 5px 0px 0px 0px;

      div[class="right"] {
        color: #b65959;
        font-weight: bold;
      }
    }

    footer {
      color: #b65959;
      font-weight: bold;
      text-align: right;
    }
  }
}

.productImage {
  height: 33px;
  position: absolute;
  inset-inline-end: 0px;
  border-radius: 6px;
  margin: 3px;
  border: 1px solid #eee;
}